import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login.component';
import { SharedModule } from './shared/shared.module';
import { PageNotFoundComponentComponent } from './page-not-found-component/page-not-found-component.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CommonService } from './shared/services/common.service';
import { NoRightClickDirective } from './shared/directives/no-right-click.directive';
import { HttpClientModule } from '@angular/common/http';
import { NgxLoadingModule } from "ngx-loading";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import * as ToastrModule from 'ngx-toastr';
import { ToastrModule } from 'ngx-toastr';
import { NgbDateCustomParserFormatterService } from './shared/services/ngb-date-custom-parser-formatter.service';
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponentComponent,
    ForgotPasswordComponent,
    NoRightClickDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    HttpClientModule,
    NgxLoadingModule.forRoot({}),
    ToastrModule.forRoot()
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [CommonService,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatterService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
