import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../shared/services/common.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  isOtpSent: boolean;
  otp: number;
  password: any;

  constructor(private router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder, private readonly commonService: CommonService) { }

  forgotForm: FormGroup;
  submitted: boolean = false;
  isLoading: boolean;

  ngOnInit(): void {
    this.forgotForm = this.formBuilder.group({
			email_id: ['', [ Validators.required, Validators.email ]],
      otp: ['', ],
      password: ['', ],
		});
  }

  public onSubmit(): void {
      this.submitted = true;
      this.isLoading = true;
      this.isOtpSent = false;
      if (this.forgotForm.invalid) {
        this.isLoading = false;
        return;
      }
      const body = this.forgotForm?.value;
      this.commonService.forgotPassword(body).subscribe((resp)=> {
        if (!resp?.success) {
          this.isLoading = false;
          this.toastr.error(resp?.message);
          return ;
        }
        this.isLoading = false;
        this.isOtpSent = true;
        this.toastr.info('Otp sent successfully');
        // this.router.navigate(['/']);
      },(error) => {
        this.isLoading = false;
        this.toastr.error("Invalid Username");
      })
    }

  public verifyOtp(): void {
    this.isLoading = true;
    const body = {
      otp: this.forgotForm?.value?.otp,
      password: this.forgotForm?.value?.password,
    }
    this.commonService.verifyOtp(body).subscribe((resp)=> {
      if (!resp?.success) {
        this.isLoading = false;
        this.toastr.error(resp?.message);
        return ;
      }
      this.isLoading = false;
      this.isOtpSent = true;
      this.toastr.info('You have been Logged in successfully ');
      this.router.navigate(['/']);
    },(error) => {
      this.isLoading = false;
      this.toastr.error("Invalid OTP");
    })
  }
}
