<section class="header-wrapper">
  <div class="container-fluid custom-container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-9">
        <div class="left_header_wrapper">
          <div class="mobile_menu">
            <div>
              <li class="logo"> <img src="../../../assets/images/logo.png"> </li>
            </div>
            <div>
              <label for="toggle" class="toggleBtn">
                <div class="tg_bars" onclick="myFunction(this)">
                  <div class="bar1"></div>
                  <div class="bar2"></div>
                  <div class="bar3"></div>
                </div>
              </label>
            </div>
          </div>

          <input type="checkbox" id="toggle">
          <ul class="nav-menu">
            <li class="logo mb-logo" (click)="redirectTo('home')"><a> <img src="../../../assets/images/wh-logo.png"> </a></li>
            <li class="logo dsk-logo" (click)="redirectTo('home')"><a> <img src="../../../assets/images/logo.png"> </a></li>
            <li (click)="redirectTo('home')"> <a> Home </a> </li>
            <li>
              <a class="accordion"> About Us </a>
              <span class="menu_icon"> <i class="fa fa-sort-desc" aria-hidden="true"></i></span>
              <ul class="sub-menu ">
                <li (click)="redirectTo('about/ministry')"><a>About the Ministry </a> </li>
                <li  (click)="redirectTo('about/service')"> <a>Indian Information Service </a></li>
                <li (click)="redirectTo('about/leadership')"> <a>Leadership </a> </li>

              </ul>
            </li>
            <li>
              <a class="accordion"> Media Units </a>
              <span class="menu_icon"> <i class="fa fa-sort-desc" aria-hidden="true"></i></span>
              <ul class="sub-menu" id="menu_tg">
                <li class="p-0" (click)="redirectTo('media/units/press_information')"> <a> PIB </a> </li>
                <li class="p-0" (click)="redirectTo('media/units/dd_news')"> <a> DD NEWS </a> </li>
                <li class="p-0" (click)="redirectTo('media/units/air')"> <a> AIR </a> </li>
                <li class="p-0" (click)="redirectTo('media/units/boc')"> <a> BOC </a> </li>
                <li class="p-0" (click)="redirectTo('media/units/dpd')"> <a> DPD </a> </li>
                <!-- <li (click)="redirectTo('media-units/press-info-bureau')"> <a> RNI </a> </li> -->
              </ul>
            </li>
            <li (click)="redirectTo('media')"> <a> Media Gallery  </a> </li>

            <li (click)="redirectTo('manual')"> <a> User Manual </a> </li>
            <li (click)="redirectTo('contact')"> <a> Contact Us </a> </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="right_header_wrapper ">
          <ul class="right_menu row">
            <li class="home_srch col-8">
              <input type="text" name="search" class="srch_control" placeholder="Search">
              <button class="srch_icon"> <img src="../../../assets/images/search_icon.png"> </button>
            </li>
            <li class="col-4">
              <div *ngIf="isLogin">

                <div class="header_login dropdownBtn">
                  <span class="openbtn" (click)="openNav()"> <i class="fa fa-bars" aria-hidden="true"></i> </span>
                  <div class="right_toogle sidepanel"  id="mySidepanel">
                    <ul>
                      <li>  <a class="closebtn" (click)="closeNav()">×</a> </li>
                      <!-- <li> <img src="../../../../assets/images/signin_icon.png"> </li> -->
                      <li (click)="redirectTo('profile/idea-lounge')"> <a> Idea Lounge  </a> </li>
                      <li (click)="redirectTo('profile/vault')">
                        <a> Profile Vault  </a>
                      </li>
                      <li (click)="logout()"> <a> Logout  </a> </li>
                    </ul>
                  </div>
                </div>

              </div>

              <div *ngIf="!isLogin">
                <div type="button" class="header_login dropdownBtn"> Sign in
                  <span class="user_icon"> <img src="../../../assets/images/user_icon.png"> </span>
                  <div class="signinFrom">
                    <div class="form-section">
                      <form  [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                          <input type="text" placeholder="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && getLoginForm.email.errors }" class="form-control">
                        </div>
                        <div class="form-group">
                          <input type="password" placeholder="Password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && getLoginForm.password.errors }" class="form-control">
                        </div>
                        <div class="d-flex error-message" *ngIf="submitted && getLoginForm.password.errors">
                          <span class="text-error" *ngIf="getLoginForm.password.errors.required">Password is required</span>
                          <span class="text-error" *ngIf="getLoginForm.password.errors.minlength">Password is too short (minimum 6 characters required)</span>
                        </div>
                        <div class="sign-button text-white">
                        <a (click)="onSubmit()" type="submit" class="signBtn">
                          <i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i>
                          <span *ngIf="!isLoading">Sign in</span>
                        </a>
                      </div>
                      </form>
                    </div>
                    <div class="frgot-button">
                      <a (click)="redirectTo('forgot-password')" class="frgtBtn"> Forgot Password ? </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
