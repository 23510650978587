import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment'

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private readonly http: HttpClient) { }

  public BaseUrl: string = `https://www.intellisense.xyz/admin/api`;
  public ImageUrl: string = `https://www.intellisense.xyz/admin/`;

  public isLogin(): boolean {
    const id = localStorage.getItem('id');
    return id && id != '' ? true : false;
  }

  getDateFormat(date: string): any {
    let preDate = moment(date);
    let dateReturn = {
      year: parseInt(preDate.format('YYYY')),
      month: parseInt(preDate.format('MM')),
      day: parseInt(preDate.format('DD')),
    }
    return dateReturn;
  }

  getDateFormatBack(wrongFormat: any): string {
    wrongFormat.month = wrongFormat.month - 1;
    let preDate = moment(wrongFormat).format("YYYY/MM/DD");
    return preDate;
  }

  public verifyOtp(body: any): Observable<any> {
    let url = this.BaseUrl + '/verify_otp';
    return this.http.post(url, body);
  }


  public login(body: any): Observable<any> {
    let url = this.BaseUrl + '/login';
    return this.http.post(url, body);
  }

  public forgotPassword(body: any): Observable<any> {
    let url = this.BaseUrl + '/forgot_password';
    return this.http.post(url, body);
  }

  public getIIS(): Observable<any> {
    let url = this.BaseUrl + '/iis';
    return this.http.get(url);
  }

  public getMinistries(): Observable<any> {
    let url = this.BaseUrl + '/ministries';
    return this.http.get(url);
  }

  public getLeadership(): Observable<any> {
    let url = this.BaseUrl + '/leadership';
    return this.http.get(url);
  }

  public getMediaGallery(): Observable<any> {
    let url = this.BaseUrl + '/media_gallery';
    return this.http.get(url);
  }

  public getBanner(): Observable<any> {
    let url = this.BaseUrl + '/banner_image';
    return this.http.get(url);
  }

  public getCommunique(): Observable<any> {
    let url = this.BaseUrl + '/Office_Communique';
    return this.http.get(url);
  }

  public getContactDetails(): Observable<any> {
    let url = this.BaseUrl + '/contact_us';
    return this.http.get(url);
  }

  public getAboutUsFooter(): Observable<any> {
    let url = this.BaseUrl + '/about_us';
    return this.http.get(url);
  }

  public getMediaUnits(endpoint: string): Observable<any> {
    let url = this.BaseUrl + '/' +endpoint;
    return this.http.get(url);
  }

  public getPersonalInfo(): Observable<any> {
    // let url = this.BaseUrl + '/all_data';
    let url = this.BaseUrl + '/fetch_data_with_id'
    const formData = new FormData();
    // should not be required from backend
    const id = localStorage.getItem('id');
    formData.append('id', id);
    return this.http.post(url, formData);
  }
  public create_officers(body): Observable<any> {
    let url = this.BaseUrl + '/create_officers';
    return this.http.post(url, body);
  }

  public postProfileUpdate(body: any, endpoint: string): Observable<any> {
    let url = this.BaseUrl + endpoint;
    return this.http.post(url, body);
  }

}
