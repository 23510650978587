<app-header></app-header>
<div>
  <section class="mt-5 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-md-10 col-sm-12 offset-md-1">
          <div class="forgot_card">
            <div class="forgot_card_img">
              <img src="../../../assets/images/banner_img.png">
            </div>
            <div class="forgot_card_content mt-4">
              <h5 class="mb-4"> Forgot Password </h5>
              <form [formGroup]="forgotForm">
                <div class="form-group">
                  <div class="text-left" *ngIf="!isOtpSent">
                    <label>Enter the email ID associate with your account</label>
                    <input type="text" formControlName="email_id" class="form-control" placeholder="Enter your email ID" [ngClass]="{ 'is-invalid': submitted && forgotForm.controls.email_id.errors }">
                  </div>
                  <div class="text-left" *ngIf="isOtpSent">
                    <label>Enter OTP</label>
                    <input type="number" formControlName="otp" [(ngModel)]="otp" class="form-control" placeholder="Enter your OTP">
                  </div>
                  <div class="text-left" *ngIf="isOtpSent">
                    <label>Enter Password</label>
                    <input type="text" formControlName="password" class="form-control" placeholder="Enter your password">
                  </div>
                </div>
                <div class="form-group pt-4">
                  <div class="d-flex error-message" *ngIf="submitted && forgotForm.controls.email_id.errors">
                    <span class="text-error" *ngIf="forgotForm.controls.email_id.errors.required">Email is required</span>
                    <span class="text-error" *ngIf="forgotForm.controls.email_id.errors.email">Incorrect Email</span>
                  </div>
                  <button *ngIf="!isOtpSent" (click)="onSubmit()" class="sendBtn">
                    <i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i>
                      <span *ngIf="!isLoading">Send</span>
                  </button>
                  <button *ngIf="isOtpSent" (click)="verifyOtp()" class="sendBtn">
                    <!-- <i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i> -->
                      <span>Verify Otp</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</div>
<app-footer></app-footer>
