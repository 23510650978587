import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  constructor(private router: Router) { }

  @Input() public parentPage: string;
  @Input() public page: string;
  ngOnInit(): void {
  }

  public redirectTo(redirect: string): void {
    // this.router.navigate([redirect]);
    switch (redirect) {
      case 'OFFICIAL COMMUNIQUE':
        this.router.navigate(['']);
        break;
      case 'Media Units':
        this.router.navigate(['']);
        break;
      case 'About Us':
        this.router.navigate(['']);
        break;
      default:
        this.router.navigate([redirect]);
        break;
    }
  }

}
