import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../../shared/services/common.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  contactData: any;
  aboutUsData: any;

  constructor(private router: Router, private readonly commonService: CommonService) { }

  ngOnInit(): void {
    this.getContactDetails();
    this.getAboutUs();
  }

  getContactDetails(): void{
    this.commonService.getContactDetails().subscribe((data) => {
      console.log(data);
      this.contactData = data?.data;
      this.contactData = this.contactData?.[0];
    },error => {
      console.log(error);
    });
  }

  getAboutUs(): void{
    this.commonService.getAboutUsFooter().subscribe((data) => {
      console.log(data);
      this.aboutUsData = data?.data;
    },error => {
      console.log(error);
    });
  }

  public redirectTo(redirect: string): void {
    redirect = redirect === 'home' ? '' : redirect;
    this.router.navigate([redirect]);
  }

}
