import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../../shared/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public isLogin: boolean = false;
  loginForm: FormGroup;
  submitted = false;
  isLoading: boolean = false;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private readonly commonService: CommonService) { }

  get getLoginForm() { return this.loginForm.controls; }

  ngOnInit(): void {
    this.isLogin = this.commonService.isLogin();
    this.loginForm = this.formBuilder.group({
			email: ['', [ Validators.required ]],
			password: ['', [ Validators.minLength(3), Validators.required ]]
		});
  }

  public redirectTo(redirect: string): void {
    redirect = redirect === 'home' ? '' : redirect;
    this.router.navigate([redirect]);
  }

  public onSubmit(): void {
    this.submitted = true;
    this.isLoading = true;
		if (this.loginForm.invalid) {
			return;
		}
    const body = this.loginForm.value;
    this.commonService.login(body).subscribe((resp)=> {
      if (!resp?.success) {
        this.isLoading = false;
        this.toastr.error('Invalid Username Password');
        return ;
      }
      localStorage.setItem('id', resp?.data?.id);
      this.isLoading = false;
      this.toastr.info('You have been Logged in successfully ');
      this.isLogin = this.commonService.isLogin();
      this.router.navigate(['/profile']);
    },(error) => {
      this.isLoading = false;
      this.toastr.error("Invalid Username Password");
    })
  }

  public openNav(): void {
    document.getElementById("mySidepanel").style.width = "250px";
  }

  public closeNav(): void {
    document.getElementById("mySidepanel").style.width = "0";
  }

  public logout(): void {
    localStorage.removeItem('id');
    this.router.navigate(['/']);
    this.isLogin = this.commonService.isLogin();
    return;
  }



}
