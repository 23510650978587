<section>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <nav aria-label="breadcrumb" class="breadcrumb_section">
            <ol class="breadcrumb">
              <li (click)="redirectTo('')" class="breadcrumb-item"><a>Home</a></li>
              <li (click)="redirectTo(parentPage)" class="breadcrumb-item"><a>{{parentPage}}</a></li>
              <li (click)="redirectTo(page)" class="breadcrumb-item active" aria-current="page"> {{page}} </li>
            </ol>
        </nav>
      </div>
    </div>
  </div>
</section>
<!-- breadcrum  end  -->
