
	<section class="footer-section">
		<div class="container">
			<div class="row mb-4">
				<div class="col-md-12">
					<div class="footer_logo_section">
						<img src="../../../assets/images/wh-logo.png">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-4">
					<div class="ft-menu">
            <h4> Sitemap </h4>
						<ul class="font-white">
							<li (click)="redirectTo('home')"> <a>Home</a> </li>
							<li (click)="redirectTo('about/ministry')"> <a>About Us</a> </li>
              <li (click)="redirectTo('media')"> <a>Media Units</a> </li>
							<!-- <li (click)="redirectTo('manual')"> <a>User Manual</a> </li> -->
							<!-- <li (click)="redirectTo('media-units/press-info-bureau')"> <a>Media Units</a> </li> -->
							<!-- <li (click)="redirectTo('media')"> <a>Media Gallery</a> </li> -->
							<li (click)="redirectTo('contact')"> <a>Contact Us</a> </li>
							<li (click)="redirectTo('faq')"> <a>User Manual</a> </li>

						</ul>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="ft-menu ft-about font-white">
						<h4> {{aboutUsData?.heading}} </h4>
            <p>{{aboutUsData?.description}}</p>
						<!-- <a (click)="redirectTo('privacy-policy')"> Privacy Policy </a> -->
					</div>
				</div>
				<div class="col-lg-4">
					<div class="ft-menu ft_contact">
						<h4> Contact Details </h4>
						<ul>
							<li> {{contactData?.mailid}} </li>
							<li> {{contactData?.phone}} </li>
							<li> {{contactData?.website || 'www.iis.com'}} </li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</section>
